import React from "react"
import data from "./data.json"
import JsZip from "jszip"
import styled, {createGlobalStyle} from "styled-components"
import {pdf, Document, Page, Text, StyleSheet, View, Image} from "@react-pdf/renderer"
import Logo from "./DetailedLogo.png"

// Parse the result on an answer by checking if it start's with res:
// The next part after res: will contain one of the following ResultType's
// ResultType's
// Crosswalk = 0, Zebrapad
// Crossing = 1, Geen zebrapad, wel oversteek
// Research = 2, Onderzoek naar andere mogelijkheden om een veilige oversteek te realiseren
// NoCrossing = 3, Geen oversteek

interface DataQuestion {
    id: string
    category: string
    question: string
    yes: string
    no: string
}

interface DataAnswer {
    id: string
    answer: string | null
    answer_bool: boolean | null
    files: File[]
    explanation: string | null
}

interface IState {
    questions: DataQuestion[] | null
    answers: DataAnswer[] | null
    currentIndex: number | null
    fileOpen: boolean
    commentOpen: boolean
    hovered: boolean
    start: boolean
    result: string | null
}

type ExtendedFile = File & { dataURL?: string }

const checkIfImageFile = (filename) => {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff', 'webp'];
    return imageExtensions.some(ext => filename.toLowerCase().endsWith(`.${ext}`));
}


// Asynchronous function to convert Blob or File to dataURL
const convertBlobToDataURL = async (blob: Blob): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onerror = reject;
        reader.onload = () => {
            resolve(reader.result as string);
        };
        reader.readAsDataURL(blob);
    });
}


export default class App extends React.Component<any, IState> {
    private fileInputRef = React.createRef<HTMLInputElement>()
    constructor(props) {
        super(props);
        this.state = {
            questions: null,
            answers: null,
            currentIndex: null,
            fileOpen: false,
            commentOpen: false,
            hovered: false,
            start: false,
            result: null
        }

        // Bindings
        this.onChange = this.onChange.bind(this)
        this.onExplanationChange = this.onExplanationChange.bind(this)
        this.onBackClick = this.onBackClick.bind(this)
        this.changeCallable = this.changeCallable.bind(this)
        this.onStatementButtonClick = this.onStatementButtonClick.bind(this)
        this.validateFile = this.validateFile.bind(this)
        this.handleFiles = this.handleFiles.bind(this)
        this.filesSelected = this.filesSelected.bind(this)
        this.fileInputClicked = this.fileInputClicked.bind(this)
        this.onDragOver = this.onDragOver.bind(this)
        this.onDragEnter = this.onDragEnter.bind(this)
        this.onDragLeave = this.onDragLeave.bind(this)
        this.onDrop = this.onDrop.bind(this)
        this.onDeleteFile = this.onDeleteFile.bind(this)
        this.submit = this.submit.bind(this)
        this.restart = this.restart.bind(this)
    }

    public componentDidMount(): void {
        const parsedData = JSON.parse(JSON.stringify(data)) as DataQuestion[]
        parsedData.sort((a, b) => a.id.localeCompare(b.id))
        const answers: DataAnswer[] = []
        if (parsedData.length > 0) answers.push({id: parsedData[0].id, answer: null, answer_bool: null, files: [], explanation: null})
        this.setState({
            questions: parsedData,
            answers: answers,
            currentIndex: 0
        })
    }

    public async submit(): Promise<void> {
        const { answers, questions, result } = this.state
        if (answers == null || questions == null || result == null) return
        const zip = new JsZip()
        const folder = zip.folder("overstreek")
        if (folder === null) return
        answers.forEach(value => {
            value.files.forEach(subValue => folder.file(subValue.name, subValue.arrayBuffer()))
        })

        // Map files in answers to arrayBuffers and convert image files to base64 URLs
        await Promise.all(answers.map(async (value) => {
            value.files = await Promise.all(value.files.map(async (file: ExtendedFile) => {
                const isImage = checkIfImageFile(file.name);
                if (isImage) {
                    file.dataURL = await convertBlobToDataURL(file);
                }
                return file;
            }));
            return value;
        }));

        // Generate PDF
        await pdf(
            <Document>
                <Page size="A4" style={styles.page}>
                    <View style={styles.section}>
                        <View style={styles.result}>
                            <Text>
                                Resultaat:
                                {(() => {
                                    switch (result) {
                                        case "0":
                                            return " Zebrapad"
                                        case "1":
                                            return " Geen zebrapad, wel oversteek"
                                        case "2":
                                            return " Onderzoek naar andere mogelijkheden om een veilige oversteek te realiseren"
                                        case "3":
                                            return " Geen oversteek"
                                        default:
                                            return ""
                                    }
                                })()}
                            </Text>
                        </View>
                        {
                            answers.map(answer => {
                                const question = questions.find(value => value.id === answer.id)
                                return (
                                    <View style={styles.item} key={`question-${answer.id}`}>
                                        <Text style={styles.itemTitle}>{answer.id}: {question?.question}</Text>
                                        <Text style={styles.itemAnswer}>{answer.answer_bool ? "Ja": "Nee"}</Text>
                                        {
                                            answer.explanation !== null && answer.explanation !== ""
                                            &&
                                            (
                                                <>
                                                    <Text style={styles.itemExplanationTitle}>Uitleg:</Text>
                                                    <Text style={styles.itemExplanation}>{answer.explanation}</Text>
                                                </>
                                            )
                                        }
                                        {
                                            answer.files !== null && answer.files.length > 0
                                            &&
                                            (
                                                <>
                                                    <Text style={styles.itemFilesTitle}>Bestanden:</Text>
                                                    {
                                                        answer.files.map((subValue: ExtendedFile) => (
                                                            checkIfImageFile(subValue.name) ? <Image key={`question-file-${subValue}`} style={styles.itemFilesFile} src={subValue.dataURL} /> : <Text key={`question-file-${subValue}`} style={styles.itemFilesFile}>{subValue.name}</Text>
                                                        ))
                                                    }
                                                </>
                                            )
                                        }
                                    </View>
                                );
                            })
                        }
                    </View>
                </Page>
            </Document>
        ).toBlob().then(res => folder.file("oversteek.pdf", res))

        zip.generateAsync({type: "blob"})
            .then(res => {
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    return window.navigator.msSaveOrOpenBlob(res)
                } else {
                    const link = document.createElement("a")
                    const url = window.URL.createObjectURL(res)
                    link.href = url
                    link.download = "overstreek.zip"
                    link.click()
                    window.URL.revokeObjectURL(url)
                    link.remove()
                }
            })
            .catch(err => console.log(err))
    }

    public restart(): void {
        const { questions } = this.state
        if (questions == null) return
        const answers: DataAnswer[] = []
        if (questions.length > 0) answers.push({id: questions[0].id, answer: null, answer_bool: null, files: [], explanation: null})
        this.setState({
            answers: answers,
            currentIndex: 0,
            fileOpen: false,
            commentOpen: false,
            hovered: false,
            start: false,
            result: null
        })
    }

    public async onChange(val: string, val_bool: boolean): Promise<void> {
        const { currentIndex, questions, answers } = this.state;
        if (currentIndex == null || questions == null || answers == null) return
        this.changeCallable()

        if (val.startsWith('res:')) {
            const findAnswer = answers.find(value => value.id === questions[currentIndex].id)
            if (findAnswer) {
                answers[answers.indexOf(findAnswer)].answer = val
                answers[answers.indexOf(findAnswer)].answer_bool = val_bool
            }
            this.setState({answers: answers, result: val.split('res:')[1]})
        } else {
            const findQuestion = questions.find(value => value.id === val)
            const findAnswer = answers.find(value => value.id === questions[currentIndex].id)
            if (findAnswer) {
                answers[answers.indexOf(findAnswer)].answer = val
                answers[answers.indexOf(findAnswer)].answer_bool = val_bool
            }
            if (findQuestion && answers.find(value => value.id === val) == null) answers.push({id: findQuestion.id, answer: null, answer_bool: null, files: [], explanation: null})
            if (findQuestion) this.setState({currentIndex: questions.indexOf(findQuestion)})
        }
    }

    public onExplanationChange(e: React.ChangeEvent<HTMLTextAreaElement>): void {
        e.preventDefault()
        const { currentIndex, answers } = this.state
        if (answers !== null && currentIndex !== null) {
            answers[currentIndex].explanation = e.target.value
            this.setState({
                answers: answers
            })
        }
    }

    public onBackClick(): void {
        const { currentIndex, answers, questions } = this.state
        if (currentIndex == null || answers == null || questions == null) return
        this.changeCallable()

        const answerIndex = answers.findIndex(value => value.id === questions[currentIndex].id)
        if (answerIndex === -1) return
        const questionIndex = questions.findIndex(value => value.id === answers[answerIndex-1].id)
        if (questionIndex === -1) return
        this.setState({
            currentIndex: questionIndex
        })
    }

    public changeCallable(): void {
        this.setState({
            fileOpen: false,
            commentOpen: false
        })
    }

    public onStatementButtonClick(btn: "comments" | "files"): void {
        switch (btn) {
            case "comments":
                this.setState({
                    commentOpen: !this.state.commentOpen,
                    fileOpen: false
                })
                return
            case "files":
                this.setState({
                    fileOpen: !this.state.fileOpen,
                    commentOpen: false
                })
                return
        }
    }

    public validateFile(_: File): boolean {
        return true
    }

    public handleFiles(files: FileList): void {
        const { currentIndex, questions, answers } = this.state;
        if (currentIndex == null || answers == null || questions == null) return
        const answerIndex = answers.findIndex(value => value.id === questions[currentIndex].id)
        if (answerIndex === -1) return
        for (let i=0; i < files.length; i++) {
            if (this.validateFile(files[i])) {
                answers[answerIndex].files.push(files[i])
                this.setState({
                    answers: answers,
                    hovered: false
                })
            } else {
                console.log("Invalid file");
                this.setState({
                    hovered: false
                })
            }
        }
    }

    public onDeleteFile(index: number): void {
        const { currentIndex, questions, answers } = this.state;
        if (currentIndex == null || answers == null || questions == null) return
        const answerIndex = answers.findIndex(value => value.id === questions[currentIndex].id)
        if (answerIndex === -1) return
        answers[answerIndex].files.splice(index, 1)
        this.setState({
            answers: answers
        })
    }

    public filesSelected(): void {
        if (this.fileInputRef.current?.files?.length) {
            this.handleFiles(this.fileInputRef.current.files)
        }
    }

    public fileInputClicked(): void {
        this.fileInputRef.current?.click()
    }

    public onDragOver (e: React.DragEvent): void {
        e.preventDefault()
        this.setState({
            hovered: true
        })
    }

    public onDragEnter (e: React.DragEvent): void {
        e.preventDefault()
        this.setState({
            hovered: true
        })
    }

    public onDragLeave (e: React.DragEvent): void {
        e.preventDefault()
        this.setState({
            hovered: false
        })
    }

    public onDrop (e: React.DragEvent): void {
        e.preventDefault()
        const files = e.dataTransfer.files;
        if (files.length) {
            this.handleFiles(files);
        } else {
            this.setState({
                hovered: false
            })
        }
    }

    public render() {
        return (
            <PageWrapperStyle>
                <GlobalStyle/>
                <PageContentWrapperStyle>
                    <TopBorderSection/>
                    {
                        (this.state.questions === null || this.state.answers === null || this.state.currentIndex === null)
                        &&
                        <p>Loading...</p>
                    }
                    {
                        (this.state.questions !== null && this.state.answers !== null && this.state.currentIndex !== null && this.state.result !== null)
                        &&
                        <StartSection>
                            <LogoSection>
                                <img src={Logo} alt={"Parkeagle Logo"}/>
                            </LogoSection>
                            <StartTitle>
                                Einde
                            </StartTitle>
                            <StartSubtitle>
                                Resultaat:
                                {(() => {
                                    switch (this.state.result) {
                                        case "0":
                                            return " Zebrapad"
                                        case "1":
                                            return " Geen zebrapad, wel oversteek"
                                        case "2":
                                            return " Onderzoek naar andere mogelijkheden om een veilige oversteek te realiseren"
                                        case "3":
                                            return " Geen oversteek"
                                        default:
                                            return ""
                                    }
                                })()}
                            </StartSubtitle>
                            <StartButtonSection>
                                <StartButton onClick={() => this.submit()}>
                                    Exporteer
                                </StartButton>
                                <RestartButton onClick={() => this.restart()}>
                                    Opnieuw
                                </RestartButton>
                            </StartButtonSection>
                        </StartSection>
                    }
                    {
                        (this.state.questions !== null && this.state.answers !== null && this.state.currentIndex !== null && !this.state.start && this.state.result === null)
                        &&
                        <StartSection>
                            <LogoSection>
                                <img src={Logo} alt={"Parkeagle Logo"}/>
                            </LogoSection>
                            <StartTitle>
                                VOP
                            </StartTitle>
                            <StartSubtitle>
                                Aan de hand van deze vragenlijst kunt u bepalen of er wel of geen zebrapad nodig is.
                            </StartSubtitle>

                            <StartButtonSection>
                                <StartButton onClick={() => this.setState({start: true})}>
                                    Start
                                </StartButton>
                            </StartButtonSection>
                        </StartSection>
                    }
                    {
                        (this.state.questions !== null && this.state.answers !== null && this.state.currentIndex !== null && this.state.start && this.state.result === null)
                        &&
                        <>
                            <QuestionLogo>
                                <img src={Logo} alt={"Parkeagle Logo"}/>
                            </QuestionLogo>
                            {/*// @ts-ignore*/}
                            <BackButton onClick={this.onBackClick} visibility={this.state.answers.findIndex(value => value.id === this.state.questions[this.state.currentIndex].id) >= 1 ? 1 : 0}/>
                            <QuestionSection>
                                <QuestionSectionInner>
                                    <QuestionCategory>{this.state.questions[this.state.currentIndex].category}</QuestionCategory>
                                    <QuestionQuestion>{this.state.questions[this.state.currentIndex].question}</QuestionQuestion>
                                    <StatementButtonSection>
                                        <StatementButton onClick={() => this.onStatementButtonClick("comments")} className={`${this.state.commentOpen && 'selected'} ${this.state.fileOpen && 'not-selected'}`} type={"button"}>
                                            Commentaar
                                        </StatementButton>
                                        <StatementButton onClick={() => this.onStatementButtonClick("files")} className={`${this.state.fileOpen && 'selected'} ${this.state.commentOpen && 'not-selected'}`} type={"button"}>
                                            Bestanden toevoegen
                                        </StatementButton>
                                    </StatementButtonSection>
                                </QuestionSectionInner>
                                <StatementArea visible={(this.state.commentOpen || this.state.fileOpen) ? 1 : 0}>
                                    <StatementAreaClose onClick={this.changeCallable}>
                                        Sluiten
                                    </StatementAreaClose>
                                    {
                                        this.state.commentOpen
                                        &&
                                        // @ts-ignore
                                        <CommentSection value={this.state.answers.find(value => value.id === this.state.questions[this.state.currentIndex].id).explanation ?? ""} rows={4} onChange={this.onExplanationChange}/>
                                    }
                                    {
                                        this.state.fileOpen
                                        &&
                                        <FilesDiv>
                                            <DropContainerStyle hovered={this.state.hovered} onDragOver={this.onDragOver} onDragEnter={this.onDragEnter} onDragLeave={this.onDragLeave} onDrop={this.onDrop} onClick={this.fileInputClicked}>
                                                <DropContainerMessageStyle>
                                                    <DropContainerUploadIconStyle className={"fas fa-upload"}/>
                                                    Drag & Drop bestanden hierheen of klik om bestand(en) te selecteren
                                                </DropContainerMessageStyle>
                                                <DropContainerFileInputStyle
                                                    ref={this.fileInputRef}
                                                    type={"file"}
                                                    multiple
                                                    onChange={this.filesSelected}
                                                />
                                            </DropContainerStyle>
                                            <ListGroup>
                                                {this.state.answers.find(value => value.id === this.state.questions![this.state.currentIndex!].id)?.files.map((value, index) => (
                                                    <ListItem key={Math.random()}>
                                                        <ListItemLeft>
                                                            <ListItemName>{value.name}</ListItemName>
                                                        </ListItemLeft>
                                                        <ListItemRight>
                                                            <ListItemDelete onClick={() => this.onDeleteFile(index)}>
                                                                <i className={"fas fa-times"}/>
                                                            </ListItemDelete>
                                                        </ListItemRight>
                                                    </ListItem>
                                                ))}
                                            </ListGroup>
                                        </FilesDiv>
                                    }
                                </StatementArea>
                            </QuestionSection>
                            <ButtonSection>
                                {/*// @ts-ignore*/}
                                <AgreeButton agree={1} type={"button"} onClick={() => this.onChange(this.state.questions[this.state.currentIndex].yes, true)}>Ja</AgreeButton>
                                {/*// @ts-ignore*/}
                                <AgreeButton agree={0} type={"button"} onClick={() => this.onChange(this.state.questions[this.state.currentIndex].no, false)}>Nee</AgreeButton>
                            </ButtonSection>
                            {/*<input type={"file"} multiple={false} onChange={(e) => this.onTestFileChange(e, true)}/>*/}
                            {/*<input type={"file"} multiple={false} onChange={(e) => this.onTestFileChange(e, false)}/>*/}
                            {/*<button type={"submit"} onClick={this.onSubmit}>Submit</button>*/}
                        </>
                    }
                </PageContentWrapperStyle>
            </PageWrapperStyle>
        );
    }
}

const settings = {
    green_color: "#1bcea2",
    green_hover_color: "#19c298",
    button_radius: "16px",
    button_font_size: "20px"
}

const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: 'white'
    },
    section: {
        margin: 0,
        padding: '5 20',
        flexGrow: 1,
        borderTop: "3mm solid #1bcea2"
    },
    result: {
        marginTop: 15,
        textAlign: "center"
    },
    item: {
        marginTop: "8mm",
        paddingBottom: "5mm",
        borderBottom: "1pt solid #e9ecef"
    },
    itemTitle: {
        fontSize: 12
    },
    itemAnswer: {
        marginTop: "1mm",
        fontSize: 12
    },
    itemExplanationTitle: {
        marginTop: "5mm",
        fontSize: 10
    },
    itemExplanation: {
        marginTop: "2mm",
        fontSize: 8
    },
    itemFilesTitle: {
        marginTop: "5mm",
        fontSize: 10
    },
    itemFilesFile: {
        marginTop: "2mm",
        fontSize: 8,
        marginLeft: "2mm"
    }
})

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Inter", sans-serif;
    outline: none;
  }
  body, html {
    font-size: 16px;
    line-height: 140%;
    font-weight: 400;
    font-style: normal;
    color: #303030;
  }
`

const PageWrapperStyle = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const PageContentWrapperStyle = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

const TopBorderSection = styled.div`
  height: 5px;
  width: 100%;
  background-color: ${settings.green_color};
`

const StartSection = styled.div`
  align-self: center;
  position: relative;
  width: 100%;
  max-width: 744px;
  border-radius: 12px;
  padding: 64px 50px 64px 64px;
  background: #fff;
  box-shadow: 0 0 2px rgba(0,0,0,.1),0 16px 80px rgba(0,0,0,.1);
  z-index: 2;
  margin-top: 30px;
`

const LogoSection = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  justify-content: center;
  
  & > img {
    width: 150px;
    height: auto;
  }
`

const QuestionLogo = styled.div`
  position: absolute;
  right: 20px;
  top: 28px;
  z-index: 10;
  
  & > img {
    width: auto;
    height: 32px;
  }
`

const StartTitle = styled.h1`
  margin-bottom: 12px;
  font-weight: 700;
  font-size: 32px;
  color: #000;
  line-height: 100%;
  text-align: center;
`

const StartSubtitle = styled.h2`
  margin-bottom: 132px;
  font-size: 20px;
  line-height: 120%;
  color: #000;
  font-weight: 400;
`

const StartButtonSection = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`

const StartButton = styled.button`
  font-size: ${settings.button_font_size};
  position: relative;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  border: 3px solid transparent;
  border-radius: ${settings.button_radius};
  padding: 21px 29px;
  background-color: ${settings.green_color};
  font-weight: 700;
  line-height: 22px;
  color: #fff;
  text-align: left;
  transition: all .15s;
  cursor: pointer;
  
  &:hover {
    background-color: ${settings.green_hover_color};
  }
`

const RestartButton = styled.button`
  margin-left: 20px;
  font-size: ${settings.button_font_size};
  position: relative;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  border: 3px solid transparent;
  border-radius: ${settings.button_radius};
  padding: 21px 29px;
  background-color: ${settings.green_color};
  font-weight: 700;
  line-height: 22px;
  color: #fff;
  text-align: left;
  transition: all .15s;
  cursor: pointer;
  
  &:hover {
    background-color: ${settings.green_hover_color};
  }
`

const QuestionSection = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const QuestionCategory = styled.h3`
  margin-bottom: 8px;
  font-weight: 700;
  font-size: 36px;
  color: ${settings.green_color};
  line-height: 44px;
`

const QuestionQuestion = styled.h1`
  position: relative;
  z-index: 5;
  width: 100%;
  font-weight: 500;
  font-size: 36px;
  color: #000;
  line-height: 140%;
  letter-spacing: -.02em;
`

const QuestionSectionInner = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  max-width: 864px;
  padding: 0 32px;
`

const StatementButtonSection = styled.div`
  display: flex;
  align-items: flex-end;
  margin-top: 32px;
`

const ButtonSection = styled.div`
  height: 125px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

interface AgreeButtonProps {
    agree: number
}

const AgreeButton = styled.button<AgreeButtonProps>`
  border: none;
  background-color: ${props => props.agree ? "#20a84b" : "#eb2045"};
  padding: 21px 59px;
  border-radius: ${settings.button_radius};
  color: white;
  margin: 10px;
  cursor: pointer;
  font-size: ${settings.button_font_size};
  font-weight: 700;
  transition: all .15s;
  
  &:hover {
    background-color: ${props => props.agree ? "#1b8b3e" : "#d61337"};
  }
`

interface BackButtonProps {
    visibility: number
}

const BackButton = styled.button<BackButtonProps>`
  border: none;
  position: absolute;
  top: 20px;
  left: 25px;
  width: 48px;
  height: 48px;
  border-radius: 48px;
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6.09 10.938l5.237 5.235L10 17.5 2.5 10 10 2.5l1.327 1.327L6.09 9.063H17.5v1.874H6.09z' fill='%23000'/%3E%3C/svg%3E") #e7eaeb 50%/24px no-repeat;
  transition: all .15s;
  cursor: pointer;
  visibility: ${props => props.visibility ? "visible" : "hidden"};

  &:hover {
    background-color: #d4dadc;
  }
`

const StatementButton = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  border: 2px solid rgba(0,0,0,.15);
  border-radius: 8px;
  padding: 16px;
  font-size: 20px;
  color: #000;
  line-height: 130%;
  text-align: left;
  transition: border-color .15s;
  cursor: pointer;
  background-color: transparent;
  
  &:last-child {
    margin-left: 24px;
  }
  
  &:hover {
    border-color: #505050;
  }
  
  &.selected {
    background-color: #f1f1f1; 
    border-radius: 8px 8px 0 0; 
    border-color: transparent;
  }
  
  &.not-selected {
    background-color: transparent;
    border-color: transparent;
  }
`

interface StatementAreaProps {
    visible: number
}

const StatementArea = styled.div<StatementAreaProps>`
  position: relative;
  width: 100%;
  max-width: 1080px;
  border-radius: 24px;
  padding: 104px 80px 68px 80px;
  display: flex;
  justify-content: space-between;
  background: #f1f1f1;

  ${({ visible }) => (visible ? `display: flex;` : `display: none;`)};
`

const StatementAreaClose = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  border-radius: 18px;
  padding: 4px 4px 4px 8px;
  cursor: pointer;
  transition: all .15s;
  background-color: transparent;
  border: none;
  
  &:after {
    content: "";
    width: 24px;
    height: 24px;
    margin-left: 8px;
    background: url("data:image/svg+xml;charset=utf-8,%3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='12' cy='12' r='11.5' stroke='%23000'/%3E%3Cpath d='M16.616 8.32a.676.676 0 00-.002-.955l.021.02a.676.676 0 00-.955 0l-3.532 3.532a.208.208 0 01-.296 0L8.32 7.384a.676.676 0 00-.954.002l.02-.021a.676.676 0 000 .955l3.532 3.532a.208.208 0 010 .296L7.384 15.68a.676.676 0 00.002.955l-.021-.02a.676.676 0 00.954 0l3.533-3.532a.208.208 0 01.296 0l3.532 3.533a.675.675 0 00.955-.002l-.021.021a.676.676 0 00.002-.955l-3.533-3.532a.208.208 0 010-.296l3.533-3.532z' fill='%23000'/%3E%3C/svg%3E") 50%/contain no-repeat;
  }
`

const CommentSection = styled.textarea`
  background-color: white;
  border: none;
  width: 100%;
  border-radius: 8px;
  padding: 12px 16px;
`

const FilesDiv = styled.div`
  min-height: 300px;
  width: 100%;
`

interface DropContainerProps {
    hovered: boolean;
}

const DropContainerStyle = styled.div<DropContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px auto;
  width: 90%;
  height: 100px;
  border-radius: 8px;
  border: 2px dashed ${props => props.hovered ? "#e9ecef" : "rgba(0,0,0,.4)"};
  cursor: pointer;
  & > div {
    color: ${props => props.hovered ? settings.green_color : "rgba(0,0,0,.4)"};
  }
`

const DropContainerMessageStyle = styled.div`
  text-align: center;
  color: inherit;
  font-size: 14px;
  display: flex;
  flex-direction: column;
`

const DropContainerUploadIconStyle = styled.i`
  font-size: 35px;
  text-align: center;
  margin: 0 auto;
`

const DropContainerFileInputStyle = styled.input`
  display: none;
`

const ListGroup = styled.ul`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  width: 90%;
  margin: 30px auto;
`

const ListItem = styled.li`
  position: relative;
  background-color: transparent;
  width: 100%;
  color: #495057;
  text-align: inherit;
  border-radius: 10px !important;
  border: 0 !important;
  padding: .5rem .75rem !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: .15s all;
  &:hover {
    color: #495057;
    text-decoration: none;
    background-color: white;
  }
`

const ListItemLeft = styled.div`
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
  position: relative;
  width: 100%;
`

const ListItemRight = styled.div`
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

const ListItemDelete = styled.button`
  cursor: pointer;
  color: #F44336;
  border: 0;
  background-color: transparent;
`

const ListItemName = styled.p`
  font-size: .85rem;
  font-weight: 500;
`
